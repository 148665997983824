import { format } from "date-fns";
import Calendar from "./components/Calendar";
import CalendarBody from "./components/CalendarBody";
import "./App.css";
import Kyugyobi from "./components/Kyugyobi";
import logo from "./logo.svg";

function Home() {
  const firstDayOfWeek = 1; // 0: 日曜日スタート, 1: 月曜日スタート
  const now = new Date(); // 今日を取得
  const thisYear = now.getFullYear(); // 今年を取得
  const months = []; // 今年の1-12月のDateオブジェクト

  for (let i = 0; i < 12; i++) {
    const month = new Date(thisYear, i);
    months[i] = month;
  }

  return (
    <>
      <div className="wrapper grid gap-12 mx-auto max-w-[1920px] lg:mt-16 p-6 md:px-[8svw]">
        <div className="sidebar">
          <header className="sticky top-20">
            <img
              src={logo}
              alt="株式会社シーキューブ"
              className="w-[10rem] mb-12"
            />
            <h1 className="font-bold mb-8 leading-normal lg:text-lg">
              シーキューブ
              <br />
              営業日カレンダー {thisYear}
            </h1>
            <p className="text-sm">
              株式会社シーキューブおよび弊社が運営するWebサービス共通の営業日カレンダーです。
            </p>
            <ul className="list-disc list-inside text-sm mt-4 mb-4">
              <li>CADステーションオンライン</li>
              <li>3Dプリントステーション</li>
              <li>技術者就職ナビ</li>
            </ul>
            <Kyugyobi />
          </header>
        </div>

        <div className="main">
          {/* <div className="mb-16">
            <iframe className="iframe" src="/single" width="100%"></iframe>
          </div> */}

          <div className="calendar-single">
            <Calendar
              month={now}
              firstDayOfWeek={firstDayOfWeek}
              dateFormat={"yyyy年 M月"}
            />
          </div>

          <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-12 calendar-all mt-8">
            {months.map((month, i) => (
              <div key={i}>
                <div className="md:text-lg mb-2">{format(month, "M")}月</div>
                <CalendarBody
                  month={month}
                  firstDayOfWeek={firstDayOfWeek}
                  currentMonth={month}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <footer className="p-4 text-xs text-gray-500 text-center">
        ©︎{thisYear} C.CUBE, Inc.
      </footer>
    </>
  );
}

export default Home;
